<template>
  <div class="page-topbar">
    <div
      @click="$route.name != 'home' && $router.push({ name: 'home' })"
      class="logo-area crypto"
    ></div>
    <div class="quick-area">
      <div class="pull-left">
        <ul class="info-menu left-links list-inline list-unstyled">
          <li class="topnav-item item3">
            <a
              @click="$router.push({ name: 'dashboard' })"
              class="nav-link w-text"
            >
              <i class="fa fa-sitemap mr-10"></i>Dashboard
            </a>
          </li>
        </ul>
      </div>

      <div class="pull-right">
        <ul class="info-menu right-links list-inline list-unstyled">
          <li v-if="isAuthenticated" class="profile">
            <a href="#" data-toggle="dropdown" class="toggle">
              <span>{{ address }}<i class="fa fa-angle-down"></i></span>
            </a>
            <ul class="dropdown-menu profile animated fadeIn">
              <li>
                <a href="crypto-account-setting.html">
                  <i class="fa fa-wrench"></i> Ajustes
                </a>
              </li>
              <li>
                <a href="crypto-profile.html">
                  <i class="fa fa-user"></i> Perfil
                </a>
              </li>
              <li>
                <a href="crypto-support.html">
                  <i class="fa fa-info"></i> Ayuda
                </a>
              </li>
              <li class="last">
                <a @click="disconnect"> <i class="fa fa-lock"></i> Salir </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "StartTopbar",

  components: {},
  computed: {
    isAuthenticated() {
      return this.$store.state.ethAddress.length > 0;
    },
    user() {
      return this.$store.state.user;
    },
    address() {
      return this.$store.state.ethAddress;
    },
  },
  methods: {
    async disconnect() {
      await this.$moralis.User.logOut();


      this.$store.commit("setUser", {});
      this.$store.commit("setAddress", "");
    },
    async handleCurrentUser() {
      const user = this.$moralis.User.current();
      if (user) {
        this.$store.commit("setAddress", user.attributes.ethAddress);
        this.$store.commit("setUser", user);
      }
    },
  },
  mounted() {
    this.handleCurrentUser();
  },
};
</script>

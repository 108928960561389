import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      name: 'home',
      component: () => import( /* webpackChunkName: "common" */ './views/public/Landing.vue'),
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import( /* webpackChunkName: "common" */ './views/private/Dashboard.vue'),
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/club',
      name: 'club',
      component: () => import( /* webpackChunkName: "common" */ './views/private/ClubEckn.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '',
      component: () => import('./views/auth/Auth.vue'),
      
      children: [{
          path: '/sign-in',
          name: 'sign-in',
          component: () => import('./views/auth/Login.vue')
        },
        {
          path: '/sign-up',
          name: 'sign-up',
          component: () => import('./views/auth/Register.vue')
        },
        {
          path: '/recover',
          name: 'recover',
          component: () => import('./views/auth/Recover.vue')
        },
      ]
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = Vue.prototype.$moralis.User.current()
      if (!user) {
        next({
          path: '/sign-in'
        });
      }
  }
  next()
})

export default router
<template>
  <div id="app">
    <start-topbar />
    <div class="page-container row-fluid container-fluid">
      <div class="wrapper main-wrapper row">
      <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import StartTopbar from "@/components/StartTopbar.vue";


export default {
  name: "App",
  components: {
    StartTopbar,

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  async mounted() {
    
  },
};
</script>

<style>
.ask-box.wallet {
  background: white !important;
}
.wallet .ask-info h3 {
  color: black !important;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import Moralis from './plugins/moralis'
import store from './store'
import router from './router'
import Vuelidate from 'vuelidate'


Vue.use(Vuelidate)

Vue.config.productionTip = false
Vue.prototype.$moralis =  Moralis


new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')

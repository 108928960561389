import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})


export default new Vuex.Store({
  state: {
    user: {},
    ethAddress: "",
    email: "",
    provider: "",
    ethUSDPrice: 0, 
    ethUSDPriceChange: 0
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setProvider(state, payload) {
      state.provider = payload;
    },
    setAddress(state, payload) {
      state.ethAddress = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setEthPrice(state, payload) {
      state.ethUSDPrice = payload.usd;
      state.ethUSDPriceChange = payload.usd_24h_change
    },
  },
  getters: {},
  actions: {},
  plugins: [vuexLocal.plugin]
});
